import './services.logger';
import './services.sl-payment';
app.controller('SlpRepayFormModalController', [
  '$scope',
  '$uibModalInstance',
  'slPaymentService',
  'logger',
  '$http',
  '$filter',
  'order',
  function (
    $scope,
    $uibModalInstance,
    slPaymentService,
    logger,
    $http,
    $filter,
    order,
  ) {
    let sdkContent = null;
    const slPaymentStandardErrorMessageMapping = {};
    const initSlpForm = async () => {
      // init state
      $scope.state = {
        isIniting: false,
        isSubmitting: false,
      };
      $scope.errors = {};
      // End of init state
      const checkCreditCardInfoErrorCode = [
        '4350',
        '4450',
        '4451',
        '4452',
        '4453',
        '4454',
        '4455',
        '4457',
        '4459',
        '4461',
        '4462',
        '4463',
        '4600',
      ];
      checkCreditCardInfoErrorCode.forEach((code) => {
        slPaymentStandardErrorMessageMapping[code] = $filter('translate')(
          'checkout.payment_failed.check_credit_card_info',
        );
      });

      $scope.state.isIniting = true;
      const tokenResponse = await slPaymentService.getCustomerToken();
      const customerToken = tokenResponse.data.customer_token;
      const amount =
        order.total.currency_iso === 'TWD'
          ? order.total.cents * 100
          : order.total.cents;
      const paymentConfig = {
        ...slPaymentService.getDefaultPaymentConfig(),
        amount,
        customerToken,
        paymentInstrument: {
          bindCard: {
            enable: true,
            protocol: {
              mustAccept: true,
              textType: {
                paymentAgreement: true,
                subscribeAgreement: true,
              },
              defaultSwitchStatus: false,
              switchVisible: true,
            },
          },
        },
      };
      try {
        sdkContent = await slPaymentService.renderSDK({
          paymentConfig,
          elementId: '#order-shopline-payment-form',
        });
      } catch (e) {
        console.error(e);
      } finally {
        $scope.state.isIniting = false;
        $scope.$apply();
      }
    };

    initSlpForm();

    $scope.submit = async () => {
      if ($scope.state.isIniting || $scope.state.isSubmitting) return;
      const valid = await sdkContent.validate();
      if (!valid) return;
      $scope.state.isSubmitting = true;
      $scope.errors.paymentFailed = '';
      $scope.$apply();
      const paySession = await sdkContent.getPaySession();
      if (paySession.paymentError) {
        logger.error(
          paySession.paymentError.msg || paySession.paymentError,
          paySession.paymentError,
        );
      }
      let chargeResponse;
      try {
        chargeResponse = await $http({
          method: 'POST',
          url: '/api/order_payments/' + order.order_payment_id + '/charge',
          data: {
            pay_session: paySession,
          },
        });
      } catch (error) {
        if (error.data?.message) {
          $scope.errors.paymentFailed = error.data?.message;
        } else {
          console.error('slp repay process err:', error);
        }
        $scope.state.isSubmitting = false;
        $scope.$apply();
        return;
      }

      const response = await sdkContent.pay(
        chargeResponse?.data?.data?.next_action,
      );
      if (response?.paymentError) {
        logger.error(
          response.paymentError.msg || response.paymentError,
          response.paymentError,
        );
        $scope.state.isSubmitting = false;
        $scope.errors.paymentFailed =
          slPaymentStandardErrorMessageMapping[response.paymentError.code] ||
          $filter('translate')('checkout.payment_failed');
        $scope.$apply();
      }
    };

    $scope.cancel = () => {
      if ($scope.state.isSubmitting) {
        return;
      }
      $uibModalInstance.dismiss('cancel');
    };
  },
]);
