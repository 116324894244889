import './services.logger';
import './services.recaptcha';
import './services.mobile-phone';
import shoplineCaptcha from '../../utils/shoplineCaptcha';
import { createDeferredPromise } from '../../utils/promise';

app.directive('mobileCheckProcess', [
  '$filter',
  '$interval',
  '$q',
  '$timeout',
  '$window',
  'mobilePhoneService',
  'RecaptchaService',
  'mainConfig',
  'logger',
  'slFeatureService',
  function (
    $filter,
    $interval,
    $q,
    $timeout,
    $window,
    mobilePhoneService,
    RecaptchaService,
    mainConfig,
    logger,
    slFeatureService,
  ) {
    return {
      restrict: 'A',
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.mobile-check-process.html'),
      scope: {
        resource: '=',
        mobileSignInStep: '@',
        formMode: '@',
        registrationFormSubmit: '&',
        getUserInfo: '&',
        simplifiedUi: '@',
        additionalErrorMessages: '<',
        resetRegistrationFormError: '&',
      },
      link: function (scope) {
        scope.recaptchaElementIds = {
          sendCode: 'mobile-signup-send-code-recaptcha',
          resendCode: 'mobile-signup-re-send-code-recaptcha',
        };

        scope.mobileCodeForm = {
          submitting: false,
        };
        scope.hasShoplineCaptcha =
          slFeatureService.hasFeature('shopline_captcha');
        const enableShoplineCaptcha =
          scope.hasShoplineCaptcha && scope.formMode === 'forgotPassword';
        // if isRecaptchaEnabled will not always be true in the future, then when enableShoplineCaptcha is true, isRecaptchaEnabled must be true.
        scope.isRecaptchaEnabled = true;
        scope.shoplineCaptchaListener = {
          sendCode: null,
          resendCode: null,
        };
        let deferredPromise = createDeferredPromise();
        let countdownInterval = null;
        let unsubscribe = null;

        scope.$on('mobile-step-change', function (event, step) {
          scope.mobileSignInStep = step;

          if (
            step === 'check_mobile' &&
            RecaptchaService.checkRecaptchaIsNotReady()
          ) {
            logger.error('failed to load recaptcha', {
              grecaptcha: window.grecaptcha,
              isRecaptchaEnterprise: mainConfig.isRecaptchaEnterprise,
            });
            if (scope.simplifiedUi) {
              scope.mobileCodeForm.errorMessages = [
                $filter('translate')(
                  'session.mobile_signup.recaptcha.load.failure',
                ),
              ];
            }
          }
        });

        scope.goBack = function ($event) {
          if (!scope.mobileCodeForm.submitting) {
            RecaptchaService.reset('submit-btn');
            $event.preventDefault();
            scope.mobileCodeForm.errorMessages = null;
            if (scope.resetRegistrationFormError)
              scope.resetRegistrationFormError();
            scope.$emit('mobile-step-change');
            if (countdownInterval) $interval.cancel(countdownInterval);
          }
        };

        scope.sendCode = function (
          shoplineCaptchaMToken,
          shoplineCaptchaDFPToken,
        ) {
          let sendCodeFunc;
          switch (scope.formMode) {
            case 'forgotPassword':
              sendCodeFunc = 'sendForgetPasswordCode';
              break;
            case 'registration':
              sendCodeFunc = 'sendSignUpCode';
              break;
            case 'member_center':
            default:
              sendCodeFunc = 'sendMobileChangeCode';
          }
          mobilePhoneService[sendCodeFunc]({
            countryCallingCode: scope.resource.countryCallingCode,
            mobilePhone: scope.resource.mobilePhone,
            recaptchable: scope.isRecaptchaEnabled,
            m_token: shoplineCaptchaMToken,
            dfp_token: shoplineCaptchaDFPToken,
          }).then(
            function () {
              scope.mobileCodeForm.submitting = false;
              scope.mobileSignInStep = 'enter_code';
              scope.resendCountdown = 60;
              countdownInterval = $interval(function () {
                scope.resendCountdown--;
                if (scope.resendCountdown === 0) {
                  $interval.cancel(countdownInterval);
                }
              }, 1000);
            },
            function (response) {
              scope.mobileCodeForm.submitting = false;
              deferredPromise = createDeferredPromise();
              scope.mobileCodeForm.errorMessages = response.data.error
                ? [response.data.error]
                : [response.data.error_messages];
            },
          );
        };

        scope.checkShoplineCaptchaOnSubmit = async () => {
          const promises = [];
          if (enableShoplineCaptcha) {
            promises.push(
              shoplineCaptcha
                .getTokenAsync()
                // should not break the flow if shoplineCaptcha.getTokenAsync fails
                .catch((error) => logger.error('getTokenAsync error', error)),
              deferredPromise.promise,
            );
          }
          scope.mobileCodeForm.submitting = true;
          scope.mobileCodeForm.errorMessages = null;
          $q.all(promises).then(
            ([shoplineCaptchaMToken, shoplineCaptchaDFPToken]) =>
              scope.sendCode(shoplineCaptchaMToken, shoplineCaptchaDFPToken),
          );
        };

        scope.formSubmit = function () {
          scope.mobileCodeForm.submitting = true;
          scope.mobileCodeForm.errorMessages = null;

          if (!scope.resource.code) {
            scope.mobileCodeForm.submitting = false;
            return;
          }

          if (scope.formMode == 'forgotPassword') {
            mobilePhoneService
              .verifyCode({
                countryCallingCode: scope.resource.countryCallingCode,
                mobilePhone: scope.resource.mobilePhone,
                code: scope.resource.code,
                isForgetPassword: true,
              })
              .then(
                function (res) {
                  $window.location.href =
                    '/users/password/edit?reset_password_token=' +
                    res.data.reset_password_token +
                    $window.location.search.replace('?', '&') +
                    '&reset=true';
                },
                function (error) {
                  scope.mobileCodeForm.errorMessages =
                    error.data.error_messages;
                  scope.mobileCodeForm.submitting = false;
                },
              );
          } else if (
            scope.formMode == 'registration' &&
            typeof scope.registrationFormSubmit === 'function'
          ) {
            scope
              .registrationFormSubmit()
              .then(function () {
                scope.mobileCodeForm.submitting = true;
              })
              .catch(function () {
                scope.mobileCodeForm.submitting = false;
              })
              .finally(function () {
                RecaptchaService.reset('sign-up-recaptcha');
              });
          } else if (
            scope.formMode == 'member_center' &&
            typeof scope.getUserInfo === 'function'
          ) {
            var params = {
              countryCallingCode: scope.resource.countryCallingCode,
              mobilePhone: scope.resource.mobilePhone,
              code: scope.resource.code,
            };

            mobilePhoneService.verifyCode(params).then(
              function success() {
                scope.mobileCodeForm.submitting = false;
                scope.$emit('mobile-step-change');
                scope.getUserInfo();
              },
              function error(error) {
                scope.mobileCodeForm.submitting = false;
                scope.mobileCodeForm.errorMessages = error.data.error_messages;
              },
            );
          }
        };

        const handleRecaptchaClickCallback = () => {
          scope.mobileCodeForm.submitting = true;
          scope.mobileCodeForm.errorMessages = null;
          scope.$apply();
        };

        const handleRecaptchaErrorCallback = () => {
          scope.mobileCodeForm.submitting = false;
          if (scope.simplifiedUi) {
            scope.mobileCodeForm.errorMessages = [
              $filter('translate')(
                'session.mobile_signup.recaptcha.token.failure',
              ),
            ];
          }
        };

        if (scope.isRecaptchaEnabled) {
          // ensure the elements have finished rendering
          $timeout(() => {
            unsubscribe = RecaptchaService.waitRecaptchaLoaded(() => {
              Object.values(scope.recaptchaElementIds).forEach((id) => {
                const recaptchaElement = document.getElementById(id);
                if (recaptchaElement) {
                  RecaptchaService.renderInvisibleRecaptchaWidget({
                    element: recaptchaElement,
                    callback: scope.checkShoplineCaptchaOnSubmit,
                    clickCallback: handleRecaptchaClickCallback,
                    errorCallback: handleRecaptchaErrorCallback,
                    timeoutCallback: handleRecaptchaErrorCallback,
                  });
                }
              });

              if (enableShoplineCaptcha) {
                scope.shoplineCaptchaListener = {
                  sendCode: shoplineCaptcha.getListener(
                    scope.recaptchaElementIds.sendCode,
                    (shoplineCaptchaMToken) => {
                      deferredPromise.resolve(shoplineCaptchaMToken);
                    },
                  ),
                  resendCode: shoplineCaptcha.getListener(
                    scope.recaptchaElementIds.resendCode,
                    (shoplineCaptchaMToken) => {
                      deferredPromise.resolve(shoplineCaptchaMToken);
                    },
                  ),
                };
              }
            });
          });
        }
        scope.$on('$destroy', () => {
          RecaptchaService.cancelRecaptchaTimer();
          unsubscribe?.();
          Object.values(scope.shoplineCaptchaListener).forEach((listener) =>
            listener?.destroy(),
          );
        });
      },
    };
  },
]);
