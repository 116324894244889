import './services.feature'
import './services.checkout.js.coffee'
import './services.cart'
import './services.logger'
import './multi_checkout/services.multi-checkout'
import './services.pnotify'

import { CHECKOUT_EVENTS } from '../constants/angular-events';
import { isFunction, get } from 'lodash-es';
import { isSupportPaymentMethod } from '@payment/payment-sdk'

app.directive("checkoutCartSummary", [
  '$http'
  'featureService'
  'checkoutService'
  'cartService'
  '$compile'
  '$filter'
  '$timeout'
  '$uibModal'
  'logger'
  'cart'
  'multiCheckoutService'
  'pnotifyService'
  '$rootScope'
  'slFeatureService'
  '$injector'
  '$q'
  (
    $http
    featureService
    checkoutService
    cartService
    $compile
    $filter
    $timeout
    $uibModal
    logger
    cart
    multiCheckoutService
    pnotifyService
    $rootScope
    slFeatureService
    $injector
    $q
  ) ->
    {
      restrict: 'A'
      link: (scope, element, attrs) ->
        is711MyPickUp = get(cart, 'country') == 'MY' && get(cart, 'delivery_option.region_type') == 'cross_border_711_store_pick_up'
        # prevent user to input invalid value
        element.on "keydown", "#apply-credit-form input", (event) ->
          if event.which == 13 || event.which == 69 || event.which == 189 || event.which == 190
            event.preventDefault()

        element.on "click", ".applied-store-credits .btn-toggle", (event) ->
          element.find('.applied-store-credits .error-text').hide()
          element.find('#apply-credit-form').show()

        element.on "keydown", "#apply-member-point-form input", (event) ->
          if event.which == 13 || event.which == 69 || event.which == 189 || event.which == 190
            event.preventDefault()

        element.on "click", ".applied-member-points .btn-toggle", (event) ->
          element.find('.applied-member-points .error-text').hide()
          element.find('#apply-member-point-form').show()

        element.on "click", ".coupon-form .btn-select", (event) ->
          $uibModal.open({
            templateUrl: require('../../../../../public/themes/v1/default/views/templates.dialog.checkout-coupon-list.html'),
            controller: 'CheckoutCouponListDialogController',
            backdrop: 'true',
            windowClass: 'checkout-coupon-list-modal'
          })
          .result
          .then (res) ->
            updateCoupon res.codes[0]
          .catch () -> return;

        element.on "click", "#apply-credit-form .btn-apply", (event) ->
          creditApplyAmount = angular.element("#apply-credit-form input").val()
          return if _.isEmpty(creditApplyAmount)
          updateSummary({ credit_apply_amount: creditApplyAmount })
            .then(() ->
              if element.find('.applied-store-credits .error-text').length == 0
                $rootScope.$broadcast(CHECKOUT_EVENTS.CART.DELIVERY.RELOAD)
              element.find('#credit-apply-form').hide()
            )

        element.on "click", "#apply-member-point-form .btn-apply", (event) ->
          applyMemberPoint = angular.element("#apply-member-point-form input").val()
          return if _.isEmpty(applyMemberPoint)
          updateSummary({ apply_member_point: applyMemberPoint })
            .then(() ->
              if element.find('.applied-member-points .error-text').length == 0
                $rootScope.$broadcast(CHECKOUT_EVENTS.CART.DELIVERY.RELOAD)
            )

        if multiCheckoutService.isEnabled()
          element.on("change", "#order-payment-method", (event) ->
            updateSummary({ payment_id: event.currentTarget.value })
          )

        updateSummary = (payload, options, successCallback) ->
          options = {} if !options?
          _.defaults(options, { showLoading: true })

          if options.showLoading
            scope.state.isCartLoading = true
          checkoutService.requestPartial('cart', 'summary', payload)
            .then (res) ->
              element.html($compile(res.data)(scope))
              scope.$emit CHECKOUT_EVENTS.CART.SUMMARY.CHANGED
              scope.$emit CHECKOUT_EVENTS.CART.FORM.RELOAD

              if isFunction(successCallback)
                successCallback()
            .catch (error) ->
              logger.log("Unable to load cart form")
            .finally () ->
              scope.$emit CHECKOUT_EVENTS.CART.SUMMARY.LOADED
              if options.showLoading
                scope.state.isCartLoading = false

        element.on "keypress", "#order-coupon", (event) ->
          if event.which == 13
            event.preventDefault()
            applyCoupon()

        element.on "click", ".cart-coupon .use-coupon", (event) ->
          element.find(".cart-coupon .coupon-form").show()
          element.find(".cart-coupon .use-coupon").hide()

        element.on "click", ".cart-coupon .use-affiliate", (event) ->
          element.find(".cart-coupon .affiliate-form").show()
          element.find(".cart-coupon .use-affiliate").hide()

        element.on "click", ".cart-coupon .btn-coupon-apply", ((event) -> applyCoupon())

        scope.$on CHECKOUT_EVENTS.CART.COUPON.REMOVE, ($event, code) ->
          updateCoupon null, code

        applyCoupon = (event) ->
          coupon_code = angular.element(".cart-coupon #order-coupon").val()
          return if _.isEmpty(coupon_code)
          updateCoupon coupon_code

        updateCoupon = (addCode, removeCode) ->
          payload = if removeCode? then { remove_code: removeCode } else { coupon_code: addCode }
          updateSummary(payload, {}, () ->
            if angular.element('#coupon-code-error').length == 0
              $rootScope.$broadcast(CHECKOUT_EVENTS.CART.DELIVERY.RELOAD)
              if addCode
                pnotifyService.notify($filter('translate')('cart.coupon_code.apply_success'), {})
          )

        scope.$on CHECKOUT_EVENTS.CART.SUMMARY.LOADED, (() ->
          angular.element('[data-toggle="popover"]').popover();
        )

        scope.$on CHECKOUT_EVENTS.CART.SUMMARY.RELOAD, () ->
          scope.state.isCartLoading = true
          checkoutService.requestPartial('cart', 'summary')
            .then (res) ->
              element.html($compile(res.data)(scope))
            .catch (error) ->
              logger.log("Unable to load cart form")
            .finally () ->
              scope.$emit CHECKOUT_EVENTS.CART.SUMMARY.LOADED
              scope.state.isCartLoading = false

        if featureService.hasFeature('taxes_settings')
          scope.$on 'tax.fee.update', (event, data) ->
            updateSummary(data, showLoading: false)

        scope.$on CHECKOUT_EVENTS.CART.DELIVERY.UPDATE_FEE, (e, data) ->
          checkoutService.requestPartial(
            'cart',
            'summary',
            angular.extend({
              delivery_address: {
                logistic_code: $("#dynamic-deliver-fee-logistic-code").val(),
                logistic_codes: null
              },
              delivery_data: {
                postal_code: $("input[name='order[delivery_data][postal_code]']").val(),
              },
              read_only: true
            },
            data)
          )
          .then (res) ->
            element.html($compile(res.data)(scope))
          .then () ->
            cartService.updateCartTotal()
              .then () ->
                scope.$emit CHECKOUT_EVENTS.CART.SUMMARY.LOADED
                scope.$emit CHECKOUT_EVENTS.CART.SUMMARY.UPDATED
                updateTotalHeaderValue()
                if (data.status == 'formDefaultAddressCall')
                  if (!$("#dynamic-deliver-fee-logistic-code").val() && !is711MyPickUp)
                    $timeout(() ->
                      setDynamicDeliveryFeeWithoutFeeView()
                    , 200)
                  else
                    updateDeliveryFeeLabel()
                else
                  updateDeliveryFeeLabel()
                  scope.$emit 'delivery.fee.isUpdated'
          .catch (error) ->
            logger.log("Update delivery fee failed", error)

        updateDeliveryFeeLabel = (shouldUseDefaultText) ->
          if shouldUseDefaultText && $("#is_using_dynamic_delivery_fee").val()
            newLabel = $filter('translate')('dynamic.delivery.fee.no.state.info')
          else
            labelText = $('.delivery-fee').children('.pull-left').text().match(/.*:/g) && $('.delivery-fee').children('.pull-left').text().match(/.*:/g)[0].trim()
            newLabel = labelText + ' ' + $('#delivery-fee-label').val()
          $('.delivery-form').children('.section-header').children('.pull-right').text(newLabel)

        updateTotalHeaderValue = () ->
          newTotalLabel =
            $('.total').children('.pull-left').html()
              .replace(/<span.*<\/span>/, '')
          newTotal = $('.total').children('.pull-right').text()
          $('#summary-header-total-price').text(newTotalLabel + ' ' + newTotal)

        scope.$on CHECKOUT_EVENTS.CART.DELIVERY.RECALCULATE_FEE, (_event, data) ->
          checkoutService.requestPartial(
            'cart',
            'summary',
            angular.extend({
              delivery_address: {
                logistic_code: null,
                logistic_codes: null
              }},
              data
            )
          )
            .then (res) ->
              element.html($compile(res.data)(scope))
            .then () ->
              $timeout(() ->
                updateDeliveryFeeLabel(true)
                updateTotalHeaderValue()
                setDynamicDeliveryFeeWithoutFeeView()
              , 200)
              scope.$emit CHECKOUT_EVENTS.CART.SUMMARY.UPDATED
            .catch (error) ->
              logger.log("Recalculate delivery fee failed", error)

        element.on "destroy", (() -> element.off()) # Unbind events

        element.on "keypress", "#affiliate-code-field", (event) ->
          if event.which == 13
            event.preventDefault()
            applyAffiliate()

        element.on "click", ".cart-coupon .btn-affiliate-apply", ((event) -> applyAffiliate())

        applyAffiliate = (event) ->
          affiliateCode = angular.element("#affiliate-code-field").val()
          return if _.isEmpty(affiliateCode)
          updateAffiliate(affiliateCode)

        updateAffiliate = (addCode, removeCode) ->
          payload = if removeCode? then { remove_affiliate_code: removeCode } else { affiliate_code: addCode }
          updateSummary(payload, {}, () ->
            $rootScope.$broadcast(CHECKOUT_EVENTS.CART.DELIVERY.RELOAD)
            if element.find('#affiliate-code-error').length == 0
              pnotifyService.notify($filter('translate')('cart.affiliate_code.apply_success'), {})
          )

        setDynamicDeliveryFeeWithoutFeeView = () ->
          $('.total').removeClass('hidden')
          updateDeliveryFeeLabel(true)
          $('.dynamic-delivery-fee').children('.pull-right').html(
            $filter('translate')('dynamic.delivery.fee.no.state.info')
          );

        $(document).ready(() ->
          if ($('.dynamic-delivery-fee').length > 0)
            if (window.location.pathname == '/checkout')
              selectedAddress = $('form[name="deliveryForm"]').find('input[type="radio"]:checked')
              if !selectedAddress.length > 0 || selectedAddress.val() == 'new'
                setDynamicDeliveryFeeWithoutFeeView()
              else
                scope.$emit 'delivery.fee.isReady'
        )

        scope.$on 'delivery.fee.isUpdated', () ->
          scope.state.isDeliveryFeeUpdated = true

        checkSLPaymentMethodsAvailability = () ->
          promises = [isSupportPaymentMethod('ApplePay'), isSupportPaymentMethod('GooglePay'), isSupportPaymentMethod('WeChatPay')];
          $q.all(promises).then ([applePayRes, googlePayRes, weChatPayRes]) ->
            $rootScope.isDeviceSupportSLPApplePay = applePayRes
            $rootScope.isDeviceSupportSLPGooglePay = googlePayRes
            $rootScope.isDeviceSupportSLPWeChatPay = weChatPayRes

            hasSLPApplePay = $injector.has('hasSLPApplePay') && $injector.get('hasSLPApplePay')
            hasSLPGooglePay = $injector.has('hasSLPGooglePay') && $injector.get('hasSLPGooglePay')
            hasSLPWeChatPay = $injector.has('hasSLPWeChatPay') && $injector.get('hasSLPWeChatPay')

            if (!$rootScope.isDeviceSupportSLPApplePay && hasSLPApplePay) || (!$rootScope.isDeviceSupportSLPGooglePay && hasSLPGooglePay) || (!$rootScope.isDeviceSupportSLPWeChatPay && hasSLPWeChatPay)
              scope.$emit("checkout.cart.summary.reload")

        if slFeatureService.hasFeature('multi_checkout')
          checkSLPaymentMethodsAvailability()

    }
])
